import React, { useState, useEffect } from "react"
import { Collapse } from "react-collapse"
import { useQuery, useMutation } from "@apollo/client"
import { parseCookies, destroyCookie } from 'nookies'
import moment from "moment"
import { navigate } from "gatsby"

import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"
import { Image } from "components/anti/image/image"
import { Modal } from "components/anti/modal/modal"
import { NumFormat } from "components/anti/num-format/num-format"
import { Accordion } from "components/anti/accordion/accordion"
import Seo from "components/seo"



//assets
import circle from "assets/img/roll-animate.svg"

import { BpjsPaymentRequestQuery, getBpjsBankCodeQuery } from "components/utils/streamworx"


const BpjsPaymentMethod = () => {
    const cookies = parseCookies()

    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    const streamworx_config = { context: { clientName: 'streamworx', headers: { authorization: authTokenNew, }, } }

    const [BpjsPaymentRequest, { data: paymentRequest }] = useMutation(BpjsPaymentRequestQuery, streamworx_config)
    const { data: BpjsBankCode } = useQuery(getBpjsBankCodeQuery, streamworx_config);

    const [detailPayment, setDetailPayment] = useState()
    const [showModal, setShowModal] = useState("")
    const [methodSelected, setMethodSelected] = useState(BpjsBankCode?.getBpjsBankCode[0]?.code)
    const [moreMethod, setMoreMethod] = useState(false)
    const [expanded, setExpanded] = useState("");
    const [serviceFee, setServiceFee] = useState()
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [errMsg, setErrMsg] = useState("")


    const handleSubmit = () => {
        setBtnDisabled(true)
        const inquiryId = detailPayment.inquiryId
        const values = {
            bankCode: methodSelected,
            inquiryId
        }
        BpjsPaymentRequest({
            variables: { ...values }
        }).then(res => {
            const data = res.data.bpjsPaymentRequest
            setBtnDisabled(false)
            setShowModal("bayarTagihanModal")
            destroyCookie({}, 'detailPayment')
        }).catch(err => {
            console.log(err)
            setBtnDisabled(false)
            setErrMsg("Terjadi Kesalahan")
        })
    }

    const handleSubmitModal = () => {
        setShowModal("tagihanSuccess")
    }

    const closeModal = () => {
        setShowModal("")
    }

    const changeBank = (code, fee) => {
        setMethodSelected(code)
        setServiceFee(fee)
    }


    useEffect(() => {
        if (cookies.detailPayment && cookies.authToken) {
            setDetailPayment(JSON.parse(cookies.detailPayment))
        } else {
            navigate('/bpjskesehatan/payment')
        }
    }, [])

    useEffect(() => {
        (async () => {
            const selectBank = await BpjsBankCode
            if (BpjsBankCode) {
                setMethodSelected(selectBank?.getBpjsBankCode[0]?.code)
                setServiceFee(selectBank?.getBpjsBankCode[0]?.serviceFee)

            }
        })()


    }, [BpjsBankCode])

    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "undefined");
    };

    return (
        <Layout>
            <Seo title="Select Method" />
            <section className="py-main sc-bpjs-method">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/bpjskesehatan/payment/confirm"}
                    >
                        Kembali
                    </Button>
                    <h2>Pilih Metode Pembayaran</h2>
                    <div className="mt-5">
                        <p className="caption mb-1">Metode Pembayaran</p>
                        <ul className="payment-method-list">
                            {BpjsBankCode?.getBpjsBankCode?.slice(0, 4)?.map((method, i) => {
                                return (
                                    <li className="mb-4" key={i}>
                                        <Button className="payment-method-card py-2 px-3 btn-block" onClick={() => changeBank(method.code, method.serviceFee)}>
                                            <div className="row">
                                                <div className="col-2">
                                                    <Image className="img-fluid img-payment" alt="icon" src={method.icon} />
                                                </div>
                                                <div className="col-9 d-flex align-items-center">
                                                    <h5 className="mb-0">{method.name}</h5>
                                                </div>
                                                <div className="col-1 d-flex align-items-center">
                                                    <div className={`radio-bullet ${methodSelected === method.code && "active"}`} />
                                                </div>
                                            </div>
                                        </Button>
                                    </li>
                                )
                            })}
                            <Collapse isOpened={moreMethod}>
                                {BpjsBankCode?.getBpjsBankCode?.slice(4)?.map((method, i) => (
                                    <li className="mb-4" key={i}>
                                        <Button className="payment-method-card py-2 px-3 btn-block" onClick={() => changeBank(method.code, method.serviceFee)}>
                                            <div className="row">
                                                <div className="col-2">
                                                    <Image className="img-fluid img-payment" alt="icon" src={method.img} />
                                                </div>
                                                <div className="col-9 d-flex align-items-center">
                                                    <h5 className="mb-0">{method.name}</h5>
                                                </div>
                                                <div className="col-1 d-flex align-items-center">
                                                    <div className={`radio-bullet ${methodSelected === method.code && "active"}`} />
                                                </div>
                                            </div>
                                        </Button>
                                    </li>
                                ))}
                            </Collapse>
                        </ul>
                        {BpjsBankCode?.getBpjsBankCode.length >= 5 && (
                            <Button variant="link" className="pl-0 pt-0" onClick={() => setMoreMethod(!moreMethod)}>
                                Tampilkan Lebih Banyak <i className={`ais ${moreMethod === false ? "ai-angle-down" : "ai-angle-up"}`} />
                            </Button>
                        )}
                        <p className="caption bg-light mt-4 p-2">Tagihan</p>
                        <div className="mb-4 px-2">
                            <p className="caption mb-0">Biaya Iuran</p>
                            <p className="mb-4">Rp{NumFormat(detailPayment?.biayaIuran)}</p>
                            <p className="caption mb-0">Biaya Admin</p>
                            <p className="mb-4">Rp{NumFormat(detailPayment?.biayaAdmin)}</p>
                            {serviceFee && (
                                <>
                                    <p className="caption mb-0">Biaya Layanan</p>
                                    <p className="mb-4">Rp{NumFormat(serviceFee)}</p>
                                </>
                            )}
                            <p className="caption mb-0">Nominal yang perlu dibayar</p>
                            <h2 className="mb-4">Rp{NumFormat(detailPayment?.totalBayar + serviceFee)}</h2>
                        </div>
                        <div className="">
                            <Button variant="primary" className="btn-block" onClick={handleSubmit} disabled={btnDisabled}>
                                {btnDisabled ? (
                                    <img src={circle} className="img-fluid loading" />
                                ) : (
                                    "Bayar"
                                )}

                            </Button>
                            <div className="invalid-feedback">{errMsg}</div>

                        </div>
                    </div>
                </div>
            </section>
            <Modal
                id="bayarTagihanModal"
                // title="Pembayaran BPJS Kesehatan"
                // img={bank_bri}
                isShowing={showModal}
                // hide={closeModal}
                className="modal-md bayar-tagihan-modal sc-bpjs-kategori"
            >
                <div className="bayar-tagihan-modal-content animated fadeInDown delayp5">
                    <div className="modal-container mb-4">
                        <img
                            src={paymentRequest?.bpjsPaymentRequest?.paymentChannelIcon}
                            className={`img-fluid`}
                            alt={"logo"}
                        />
                    </div>
                    <div className="modal-container bg-light pt-4">
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Invoice</p>
                            <p className="mb-4 font-weight-bold">{paymentRequest?.bpjsPaymentRequest?.invoiceNo}</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Virtual Account</p>
                            <p className="mb-4 font-weight-bold">{paymentRequest?.bpjsPaymentRequest?.virtualAccountNumber}</p>
                        </div>
                        <div className="">
                            <p className="caption mb-0">Jumlah Iuran</p>
                            <h3 className="pb-4 mb-0">Rp{NumFormat(paymentRequest?.bpjsPaymentRequest?.totalAmount)}</h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between modal-container countdown py-2 mb-2">
                        <p className="mb-0">Batas Waktu Pembayaran</p>
                        <p className="mb-0">{moment(paymentRequest?.bpjsPaymentRequest?.expiresAt).format("DD MMM YYYY, HH:mm")}</p>
                    </div>
                    <div className="modal-container py-4 h-md-350px overflow-auto gradient-list ">
                        {paymentRequest?.bpjsPaymentRequest?.howToPay.map((how, i) => {
                            return (
                                <Accordion
                                    expandIconPlus
                                    title={how.paymentType}
                                    className={`accordion-home-update-category`}
                                    key={i}
                                    defaultExpanded={paymentRequest?.bpjsPaymentRequest?.howToPay[0].paymentType}
                                    expandedKey={how.paymentType}
                                    currentExpanded={expanded}
                                    onChange={handleAccordion(how.paymentType)}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: how.instruction }} />
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="modal-container">
                        <Button variant="primary" className="btn-block" link="/bpjs/riwayat">
                            Ke Riwayat Pembayaran
                        </Button>
                    </div>
                </div>
            </Modal>
        </Layout >
    )
}


export default BpjsPaymentMethod